import React from 'react';
import useAccountMenuOpen from '../../lib/apollo/storage/appConfig/useAccountMenuOpen';
import Signout from '../Signout';
import AccountLink from './AccountLink';
import data from './data';
import { AccountStyles } from './styles';

const Account = () => {

  const { accountMenuOpen, toggleAccountMenu } = useAccountMenuOpen();

  return (
    <AccountStyles className={accountMenuOpen ? 'open' : ""}>
      <ul onClick={() => toggleAccountMenu()}>
        {data.map(({ href, text, image, target, icon }) => (
          <AccountLink key={text} href={href} text={text} image={image} target={target} icon={icon} />
        ))}
        <li key="signout">
          <Signout />
        </li>
      </ul>
    </AccountStyles>
  );
};

export default Account;