"use client"
import { createGlobalStyle } from "styled-components";
import { device } from '../utils/deviceSizes';


export const GlobalStyle = createGlobalStyle`
  :root {
    /* Define colors as colors */
    --black: #2b2926;
    --cream: #eeeeee;
    --lightOrange: #ff9100;
    --orange: #EA4C2F;
    --lightYellow:#fff3cd;
    --yellow: #F08551;
    --green: #5cb85c;
    --red: #cb1d00;
    --blue: #008cd0;
    --purple: #9b2a9a;
    --pink: #9b2a9a;
    --pureBlack: #000;
    --pureWhite: #fff;
    --lightGrey: #e8e8e8;
    --lightGrey1: #f7f7f7;
    --lightGrey2: #EBEAEA;
    --lightGrey3: #dbdbdb;
    --grey: #8e8e8e;
    --grey1: #CBCBCB;
    --grey2: #CCCCCC;
    --darkGrey: #292929;
    --darkGrey1:rgb(20, 20, 20);
    --lightBrown:  #a37554;

    /* Define colors intentions */
    --background: var(--cream);
    --textColor: var(--black);
    --lineColor: var(--lightGrey);
    --primary: var(--orange);
    --secondary: var(--darkGrey);
    --primaryDark20: var(--orangeDark20);
    --cardBg: var(--pureWhite);
    --headerBg: var(--black);
    /* Styles */
    --line: 2px solid var(--lineColor);
    --transition: 0.3s ease;
    --box-shadow:  0px 4px 6px #00000029;
    --selected-box-shadow:  0px 4px 6px var(--primary);
    /* Types */
    --bodyFont: "Glacial Indifference", sans-serif;
    --baseFontSize: 16px;
    --h1: 3rem;
    --h2: 2.5rem;
    --h3: 2rem;
    --h4: 1.5rem;
    --h5: 1.5rem;
    --heading-line: 1rem;
    --smallText: 0.8rem;
    /* Elevation */
    --level--1: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --level-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --level-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --level-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --level-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  @font-face {
    font-family: "Glacial Indifference";
    src: url("/fonts/glacial-indifference/GlacialIndifference-Regular.eot");
    src: url("/fonts/glacial-indifference/GlacialIndifference-Regular.woff2") format("woff2"), url("./fonts/glacial-indifference/GlacialIndifference-Regular.woff") format("woff"), url("./fonts/glacial-indifference/GlacialIndifference-Regular.ttf") format("truetype"), url("./fonts/glacial-indifference/GlacialIndifference-Regular.otf") format("opentype"), url("./fonts/glacial-indifference/GlacialIndifference-Regular.svg") format("svg");
    font-display: swap;
  }
  @font-face {
    font-family: "Glacial Indifference";
    src: url("/fonts/glacial-indifference/GlacialIndifference-Bold.eot");
    src: url("/fonts/glacial-indifference/GlacialIndifference-Bold.woff2") format("woff2"), url("./fonts/glacial-indifference/GlacialIndifference-Bold.woff") format("woff"), url("./fonts/glacial-indifference/GlacialIndifference-Bold.ttf") format("truetype"), url("./fonts/glacial-indifference/GlacialIndifference-Bold.otf") format("opentype"), url("./fonts/glacial-indifference/GlacialIndifference-Bold.svg") format("svg");
    font-weight: bold;
    font-display: swap;
  }
  html {
    box-sizing: border-box;
    font-size: 62.5%;
    color: #fff;
    line-height: 1.4;   
  }
  body {
    margin: 0;
    font-size: 1.6rem;
    font-family: "Glacial Indifference", sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;

  }

  main {
    
  background: var(--secondary) url("/images/background.webp");
  background-size: cover;

  background-position-y: -20rem;
  height: 100%;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  .page-container {
    margin-top: 10rem;
    width: 150rem;
    /* background: #fff; */
    color: #fff;
    position: relative;


    @media ${device.tablet} {
      margin-top: 8rem;
    }

    @media ${device.laptopL} {
      padding: 0;
    }
  }

  &.transparent-background {
    margin-bottom: 0;
    .page-container {
      background: transparent;
    }
  }

  }
  button {
    background: var(--primary);
    color: var(--pureWhite);
    border: none;
    padding: 1rem 1.75rem;
    outline: none;
    cursor: pointer;
  }
  *, *:before, *:after {
    box-sizing: border-box;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    list-style: none;
  }

  a, a:visited {
    color: inherit;
    text-decoration: none;
  }
  h1,h2,h3,h4,h5,h6, p {
    margin: 0;
  }
  ::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
  }
  ::selection {
    background: #b3d4fc;
    text-shadow: none;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }
  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }
  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }
  textarea {
    resize: vertical;
  }
  .hidden,
  [hidden] {
    display: none !important;
  }
  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .sr-only.focusable:active,
  .sr-only.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
  }
  .invisible {
    visibility: hidden;
  }
  .clearfix::before,
  .clearfix::after {
    content: " ";
    display: table;
  }
  .clearfix::after {
    clear: both;
  }
  .bold {
    font-weight: bold;
  }
  .clickable {
    cursor: pointer;
  }
  .text-center {
    text-align: center;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
  @media print,
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 1.25dppx),
    (min-resolution: 120dpi) {
    /* Style adjustments for high resolution devices */
  }
  @media print {
    *,
    *::before,
    *::after {
      background: #fff !important;
      color: #000 !important;
      /* Black prints faster */
      box-shadow: none !important;
      text-shadow: none !important;
    }
    a,
    a:visited {
      text-decoration: underline;
    }
    a[href]::after {
      content: " (" attr(href) ")";
    }
    abbr[title]::after {
      content: " (" attr(title) ")";
    }
    a[href^="#"]::after,
    a[href^="javascript:"]::after {
      content: "";
    }
    pre {
      
      white-space: pre-wrap !important;
    }
    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    tr,
    img {
      page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
  }
   /* UTILS */

   .btn {
    background: var(--primary);
    color: var(--pureWhite);
    border: none;
    padding: 1rem 1.75rem;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.6rem;

    &.btn-delete {
      background: var(--red);
    }
    &.btn-info {
      background: var(--blue);
    }
    &.btn-success {
      background: var(--green);
    }
  }

  button.btn:disabled {
    color: gray;
  }

  .flex {
    display: flex;
    flex-direction:row !important;
  }
  .flex-col {
    flex-direction: column !important;
  }
  .justify-between {
    justify-content: space-between;
  }

  .items-center {
    align-items: center;
  }

  w-full {
    width: 100%;
  }
/* 
  .Flipped, .Flipped .Content{
            transform: rotateX(180deg);
        }
          */
  
  .self-start {
    align-self: start;
  }

  .m-0 {
    margin: 0 !important;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .mt-2 {
    margin-top: 2rem;
  }

  .mr-1 {
    margin-right: 1rem;
  }
  .ml-1 {
    margin-left: 1rem;
  }
  .mb-5 {
    margin-bottom:5rem;
  }

  .space-between {
    justify-content: space-between;
  }

  .gap-2 {
    gap:2rem;
  }
  .clickable {
    cursor: pointer;
  }

  .color {
    &__green {
      color: var(--green);
    }
    &__black {
      color: var(--black);
    }
  }

  .warning {
    path {
      fill: var(--primary);
    }
  }
  .orange {
    path {
      fill: #ff3600 !important;
    }
  }
  .error {
    color: var(--red);
  }
  .red {
    path {
      fill: var(--red) !important;
    }
  }


  .bold {
    font-weight: bold;
  }
  .capitalize {
    text-transform: capitalize;
  }

  .margin {
    &__medium {
      margin: 2rem;
    }
  }


  .fit-content {
    width: fit-content;
  }


  .text-white {
    color: #fff !important;
  }

  

  /* UTLS */

  *, *:before, *:after {
    box-sizing: border-box;
  }




  /* COMPONENTS THAT NEED SHARED STYLES */

  .sku-by-warehouse-table:not(:first-of-type) {
    .header {
      display: none;
      
    }
  }
  
  .sku-by-warehouse-table:not(:last-of-type) {
    .input-box-row {
      display:none;
    }
  }
  

  /* EDITED 3RD PARTY LIBRARIES */
    .Toastify {
      position: absolute;
      z-index: 999999;
    }


    #dropin-container {
      width: 100%;
      height: 500px;
      margin-bottom: 10rem;
    }

`;
