import { usePathname, useRouter } from "next/navigation";
import { isLoggedInVar } from "../lib/apollo/storage/appConfig/apolloVars";
import useAccountMenuOpen from "../lib/apollo/storage/appConfig/useAccountMenuOpen";
import useActiveOrder from "../lib/apollo/storage/appConfig/useActiveOrder";
import useCartItems from "../lib/apollo/storage/appConfig/useCartItems";
import useCartOpen from "../lib/apollo/storage/appConfig/useCartOpen";
import useCategories from "../lib/apollo/storage/appConfig/useCategories";
import useCompany from "../lib/apollo/storage/appConfig/useCompany";
import useIsLoggedIn from "../lib/apollo/storage/appConfig/useIsLoggedIn";
import useNotifications from "../lib/apollo/storage/appConfig/useNotifications";
import useStore from "../lib/apollo/storage/appConfig/useStore";
import useUser from "../lib/apollo/storage/appConfig/useUser";
import { CustomerDefinition, StorePriority } from "src/generated/graphql";
import Cookies from "js-cookie"

const useHandleSignout = () => {
  const router = useRouter();
  const { addCompany } = useCompany();
  const { addCartItems } = useCartItems();
  const { addNotifications } = useNotifications();
  const { addUser } = useUser();
  const { addActiveOrder } = useActiveOrder();
  const { addStore } = useStore();
  const { addCategories } = useCategories();
  const { addIsLoggedIn } = useIsLoggedIn();

  const { toggleCart, cartOpen } = useCartOpen();
  const { toggleAccountMenu, accountMenuOpen } = useAccountMenuOpen();

  const pathname = usePathname()

  return () => {
    router.push("/login");

    localStorage?.removeItem("token");
    addCartItems([]);
    addCompany({});
    addIsLoggedIn(false);
    addNotifications([]);
    addStore({
      id: "",
      name: "",
      address: "",
      warehouseId: "",
      line_1: "",
      city: "",
      zipCode: "",
      country: "",
      external_ref: '',
      basePrice: "",
      termsAndConditionsAccepted: false,
      users: [],
      priority: StorePriority.Medium,
      customerDefinition: CustomerDefinition.Other,
      warehouseName: "",
      currency: "",
      salesperson: "",
      status: "",
    });
  
    addActiveOrder({ id: "", shippingCost: 0, totalPrice: 0, totalVAT: 0, discount: 0, vatRate:0});
    addUser({ id: "", email: "" });
    addCategories({ target: '', categories: [] })

    Object.keys(Cookies.get()).forEach(cookie => {
      Cookies.remove(cookie) // removed!
    })
    

    if (cartOpen) toggleCart();
    if (accountMenuOpen) toggleAccountMenu();
  };
};

export default useHandleSignout;
