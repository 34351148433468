import { useQuery, useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from './apolloVars';
import { GET_IS_LOGGED_IN } from '@apollo-custom/queries';

export default function useIsLoggedIn() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  // Function to update the logged-in state.
  const addIsLoggedIn = (newIsLoggedIn) => {
    isLoggedInVar({isLoggedIn: newIsLoggedIn});
  };

  // Optionally, if there's a need to synchronize with Apollo cache or perform
  // any side effects when isLoggedIn changes, use useEffect here.
  // useEffect(() => {
  //   // Sync or side-effect logic here.
  // }, [isLoggedIn]);

  return {
    isLoggedIn: isLoggedIn.isLoggedIn,
    addIsLoggedIn,
  };
}
