import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useParams, usePathname, useRouter } from "next/navigation";

// APOLLO
import useIsLoggedIn from "../../lib/apollo/storage/appConfig/useIsLoggedIn";
import useUser from "../../lib/apollo/storage/appConfig/useUser";

// TYPES

// COMPONENTS
// import Signout from '../Signout';

// STYLES
import { NavStyles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import useAccountMenuOpen from "../../lib/apollo/storage/appConfig/useAccountMenuOpen";
import useStore from "../../lib/apollo/storage/appConfig/useStore";
import CartWidget from '@components/Cart/CartWidget';
import useCartOpen from '@apollo-custom/storage/appConfig/useCartOpen';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { useTranslations } from 'next-intl';

interface NavProps {
  className: string;
}

const Nav: React.FC<NavProps> = ({ className }) => {
  const { isLoggedIn } = useIsLoggedIn();
  const [cartClicked, setCartClicked] = useState(false);
  const { toggleAccountMenu } = useAccountMenuOpen();
  const { email } = useUser();
  const { name } = useStore();
  const pathname = usePathname()

  useEffect(() => {
    const cart = document.getElementsByClassName("laptop-cart")[0];
    if (cartClicked && cart) {
      window.scrollTo({
        top: cart.getBoundingClientRect().top - 100 + window.pageYOffset,
        behavior: "smooth",
      });
      setCartClicked(false);
    }
  }, [pathname]);

  const [navClass, setNavClass] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      setNavClass("logged-in");
    } else {
      setNavClass("logged-out");
    }
  }, [isLoggedIn]);


  const { toggleCart, cartOpen } = useCartOpen()
  const { locale } = useParams()

  const t = useTranslations("Navbar");

return (
  <NavStyles className={className}>
    <ul className={navClass}>
      {pathname.startsWith(`/${locale}/category`) && (
        <li>
          <div
            className="buttons"
            onClick={() => {
              if (cartOpen) toggleCart();
            }}
          >
            <CartWidget />
            {/* Optionally display the cart label */}
            {/* <span>{t("cart")}</span> */}
          </div>
        </li>
      )}
      <li className="account">
        <a
          onClick={() => {
            toggleAccountMenu();
          }}
        >
          <FontAwesomeIcon width={18} height={18} icon={faUser} />
          {" "}
          <FontAwesomeIcon width={18} height={18} className="chevron" icon={faSortDown} />
          {/* Optionally display the account label */}
          <span>{t("account")}</span>
        </a>
      </li>
      <li className="user-details">
        <span>{t("hello")}</span>
        <div>
          <p>{email}</p>
          <p>{name}</p>
        </div>
      </li>
    </ul>

    <ul className={navClass}>
      <li>
        <Link className="btn" href="/sign-up">
          {t("applyB2B")}
        </Link>
      </li>
      <li>
        <Link href="/login">{t("login")}</Link>
      </li>
    </ul>

    <LanguageSwitcher />
    {/* Uncomment if you wish to include the size finder link */}
    {/* <Link href="/size-finder" className="size-finder-link">
      {t("sizeFinder")}
    </Link> */}
  </NavStyles>
);

};

export default Nav;
