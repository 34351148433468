import React from "react";
import { ErrorStyles } from "./style";

interface ErrorProps {
  message: string;
  onClick?: () => void;
}

const Error: React.FC<ErrorProps> = ({ message, onClick = null }) => {
  return (
    <ErrorStyles>
      <p>
        Error: {message}
        {onClick && (
          <span onClick={() => onClick()}>, click here to send a new link</span>
        )}
      </p>
    </ErrorStyles>
  );
};

export default Error;
