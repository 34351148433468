import styled from 'styled-components';
import { device } from '../../utils/deviceSizes';

export const FooterStyles = styled.footer`

    display: none;

    &.hidden {
        display: none !important;
    }

    @media ${device.laptop} {
        display: flex;
    }
    padding: 2rem 5rem;
    width: 100%;
    background: var(--secondary);

    position: fixed;
    bottom: 0;
    z-index: 1000;

    
    align-items: center;
    justify-content: space-between;

    img {
        width: 3rem;
        height: 3rem;
    }

    .buttons {
        display: flex;
        /* gap: 4rem; */
        align-items: center;
    }

    .icon {
        margin-left: 3rem;
        width: 2.2rem;
        height: 2.2rem;
        path {
            fill: var(--grey)
        }

        &.selected {
            path {
                fill: var(--primary)
            }
        }
    }

`