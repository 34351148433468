import styled from "styled-components";
import { device } from "../../utils/deviceSizes";

export const FooterStyles = styled.footer`
 
  background-color: var(--darkGrey);
  color: grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
min-height: 200px;

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.footer-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.footer-link {
  color: var(--orange);
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}
.orange-link {
  color: var(--orange);
}

`;
