"use client";
import Image from "next/image";
import { useParams, usePathname, useRouter } from "next/navigation";
import React, { useState } from "react";
import styled from "styled-components";

const LANGUAGES = [
  { code: "CN", label: "Chinese" },
  { code: "SE", label: "Swedish" },
  { code: "NO", label: "Norwegian" },
  { code: "EN", label: "English" },
  { code: "DE", label: "German" },
  { code: "FR", label: "French" },
  { code: "ES", label: "Spanish" },
];

const DropdownContainer = styled.div`
  position: fixed;
  top:100px;
  right: 5px;
  display: inline-block;
  
`;

const SelectedLanguage = styled.div`
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 50px;
  max-height: 200px;
  overflow-y: auto;
`;

const MenuItem = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #000;
  &:hover {
    background-color: #3d3c3c;
  }
`;

const LanguageLabel = styled.span`
  margin-right: 10px;
  font-size: 1.2rem;
`;

export default function LanguageSwitcher() {
  const { locale } = useParams();
  const router = useRouter();
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (newLocale) => {
    // Extract supported locale codes from LANGUAGES
    const supportedLocales = LANGUAGES.map((lang) => lang.code.toLowerCase());

    // Remove leading and trailing slashes from the pathname
    const currentPathname = pathname.replace(/^\/|\/$/g, "");

    // Split the current path into segments (filter out empty segments)
    const pathParts = currentPathname.split("/").filter(Boolean);

    let restPath = pathParts;

    // If the first segment is a supported locale, remove it
    if (supportedLocales.includes(pathParts[0]?.toLowerCase())) {
      restPath = pathParts.slice(1);
    }

    // Construct the updated path with the new locale
    // Only add a slash + restPath if there are any remaining segments
    const updatedPath = `/${newLocale.toLowerCase()}${restPath.length ? `/${restPath.join("/")}` : ""}`;

    // Navigate to the updated path
    router.push(updatedPath);

    // Close the dropdown after selection
    setIsOpen(false);
  };


  const currentLanguage = LANGUAGES.find(
    (lang) => lang.code.toLowerCase() === locale
  ) || { code: "EN", label: "English" };

  return (
    <DropdownContainer>
      <SelectedLanguage onClick={() => setIsOpen(!isOpen)}>
        {/* <LanguageLabel>Choose language: </LanguageLabel> */}
        <Image
          src={`/images/flags/${currentLanguage.code.toLowerCase()}.svg`}
          alt={currentLanguage.code}
          width={30}
          height={20}
        />
      </SelectedLanguage>
      {isOpen && (
        <DropdownMenu>
          {LANGUAGES.map((lang) => (
            <MenuItem
              key={lang.code}
              onClick={() => handleChange(lang.code.toLowerCase())}
            >
              <Image
                src={`/images/flags/${lang.code.toLowerCase()}.svg`}
                alt={lang.code}
                width={30}
                height={20}
              />
              {/* <LanguageLabel>{lang.label}</LanguageLabel> */}
            </MenuItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
}
