import React from 'react';

import { SignOutStyles } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import useHandleSignout from '../../utils/useHandleSignout';

const Signout = () => {
  const handleSignout = useHandleSignout();

  return (
    <SignOutStyles className="log-out" onClick={handleSignout}>
      <FontAwesomeIcon icon={faSignOutAlt} /> Log out
    </SignOutStyles>
  );
};

export default Signout;
