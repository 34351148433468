"use client"
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

// APOLLO
import useAccountMenuOpen from '../../lib/apollo/storage/appConfig/useAccountMenuOpen';
import useCartOpen from '../../lib/apollo/storage/appConfig/useCartOpen';
import useIsLoggedIn from '../../lib/apollo/storage/appConfig/useIsLoggedIn';

// COMPONENTS
import CartWidget from '../Cart/CartWidget';

// STYLES
import { FooterStyles } from './style';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUser from '../../lib/apollo/storage/appConfig/useUser';


const MobileFooter = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { email } = useUser();
  const { toggleCart, cartOpen } = useCartOpen();
  const { toggleAccountMenu, accountMenuOpen } = useAccountMenuOpen()

  return (
    <FooterStyles className={!isLoggedIn || !email ? "hidden" : ""}>

      <Link href="/home" onClick={() => {
        if (accountMenuOpen) toggleAccountMenu()
        if (cartOpen) toggleCart()
      }}>
        <Image width={120} height={100} alt="logo" src={"/images/logo-icon.png"} />
      </Link>

      <div className="buttons">
        <CartWidget />
        <FontAwesomeIcon onClick={() => {
          toggleAccountMenu()
          if (cartOpen) toggleCart()
        }} className={`icon ${accountMenuOpen && 'selected'}`} icon={faUser} />
      </div>
    </FooterStyles >
  );
};

export default MobileFooter;