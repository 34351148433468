import React from 'react';
import useCompany from '../../lib/apollo/storage/appConfig/useCompany';
import styled from "styled-components"

const NoSegmentationWarningStyles = styled.div`
  max-width: 60rem;
  padding: 2rem;
  h1 {
    margin-bottom: 3rem;
    font-size: 2.4rem;
  }
`

export const ContactSales = () => {
  const { responsiblePerson, environment } = useCompany();
  return (
    <section className='contact-section flex gap-2'>
      {responsiblePerson?.email && (
        <div>
          <h3>Sales representative </h3>
          <p>{responsiblePerson.name}</p>
          <div className='flex flex-col'>
            <p>Email: <a href={`mailto:${responsiblePerson.email}`}>{responsiblePerson.email}</a></p>
            {responsiblePerson.phoneNumber &&
              <p>Phone: <a href={`callto:${responsiblePerson.phoneNumber}`}>{responsiblePerson.phoneNumber}</a></p>
            }
          </div>
        </div>
      )}
      {environment === "NO" ? (
        <div>
          <h3>Non-stop dogwear AS</h3>
          <p>Holtevegen 395, 2372 Brøttum </p>
          <div className='flex flex-col'>
            <p>Email: <a href={`mailto:sales@nonstopdogwear.com`}>sales@nonstopdogwear.com</a></p>
            <p>Phone:<a href={`callto:+4795092413`}> +47 950 92 413</a></p>
          </div>
        </div>
      ) : (
        <div>
          <h3>Non-stop dogwear Sweden</h3>
          <p>Östersund</p>
          <div className='flex flex-col'>
            <p>Email: <a href={`mailto:sweden@nonstopdogwear.com`}>sweden@nonstopdogwear.com</a></p>
            <p>Phone:<a href={`callto:+43739198041`}> +43 739 198 041</a></p>
          </div>
        </div>
      )}
    </section>
  )
}

const NoSegmentationWarning = () => {



  return (
    <NoSegmentationWarningStyles>
      <h1>
        Sorry you do not seem to have access to our products, please contact sales@nonstopdogwear.com
      </h1>
      <ContactSales />
    </NoSegmentationWarningStyles>
  )
}

export default NoSegmentationWarning;