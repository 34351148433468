"use client"
import styled from "styled-components";
import { device } from "../../utils/deviceSizes";

export const HeaderStyles = styled.header`
  height: 10rem;
  width: 100%;
  /* transform-style: preserve-3d; */

  position: fixed;

  /* overflow: hidden; */
  z-index: 1000;

  @media ${device.laptopL} {
    padding: 0 1rem;
    left: 0;
  }

  @media ${device.tablet} {
    height: 8rem;
  }

  .product-search {
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1; /* Allows it to take available space evenly */
  max-width: 400px; /* Adjust width as needed */
}
  .container {
    margin: 0 auto;
    width: 150rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    
    @media ${device.laptopL} {
      width: 100%;
    }
    
    @media ${device.mobileXL} {
      gap:0;
      justify-content: unset;
    }


    &__left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;

      color: #fff;

      @media ${device.tablet} {
        width: 100%;
        justify-content: space-between;
      }
      @media ${device.mobileL} {
        display: block;
        max-width: fit-content;
      }

      .link-targets {
        display: none;

        @media ${device.laptop} {
          display: none;
          }
        @media ${device.laptopM} {
          display: flex;
        }

        &__opener {
          display: none;
          
          @media ${device.laptopM} {
            display: block;
            /* margin-right: 1rem; */
            width: 12rem;
            text-align: left;
            svg {
              margin-bottom: 10px;
              margin-left: 0.5rem;
            }
          }
          @media ${device.mobileXL} {
            display: none;
          }
        }

        @media ${device.laptopM} {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          z-index: 1;
          position: fixed;
          right: 0;
          top: 8rem;
          background: var(--secondary);
          padding: 1rem 1rem 1rem 2rem;

          transition: all 0.3s;

          transform: translateY(-50%);
          opacity: 0;

          &.open {
            transform: translateY(0);
            opacity: 1;
          }

          a {
            margin-bottom: 1rem;

            width: 12rem;
            align-items: center;
            justify-content: center;
            /* line-height: 5rem; */

            &.selected {
              display: unset;
              color: var(--lightGrey);

              /* color: var(--primary); */
            }
          }
        }
     
      }

      .targets-container {
        
        &.hidden {
          display: none;
          /* display: unset; */
        }
      }

      .link-targets-desktop {
        display: flex;
        justify-content: space-between;
        width: max-content;
        align-items:center;
        a:not(:last-of-type) {
          margin-right: 2rem;
        }
        @media ${device.laptopM} {
          display: none;
        }
      }

      a {
        font-size: 1.8rem;
        cursor: pointer;
        width: fit-content;

        /* @media ${device.tablet} {
          display: none;
        } */

        &.selected {
          color: var(--primary);
        }
      }
    }

    .product-search-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
      @media ${device.tablet} {
        display: none;
      }
    }
    .sku-search {
      min-width: 30rem;
      max-width: 30rem;
      color: var(--black);

      &.hidden {
        display: none;
        /* display: unset; */
      }

      @media ${device.laptop} {
        min-width: 20rem;
        max-width: 20rem;
      }
      @media ${device.mobileL} {
        /* display: none; */
        width: 25rem;
        min-width: unset;
        max-width: unset;
      }
    }
  }

  background: var(--secondary);
`;

export const NavStyles = styled.nav`
  color: #fff;
  display: flex;
  justify-content: flex-end !important;
  width: fit-content;
  justify-self: flex-end;
  flex-direction: column;
  
  @media ${device.tablet} {
    display: none;
  }
  ul {
    
    align-items: center;
    
    display: none;
    &:first-of-type {
      &.logged-in {
      display: flex !important; 
      }
    }
    &:nth-of-type(2) {
      display: flex; 
      &.logged-in {
      display: none; 
      }
    }
    
    li {
      &:not(:last-of-type) {
        margin-right: 2rem;
        margin-left: 2rem;

        @media ${device.laptop} {
          margin-right: 1rem;
          margin-left: 1rem;
        }
      }
      svg {
        path {
          fill: var(--primary);
        }
      }

      .fa-shopping-cart {
        display: none;
        @media ${device.laptopL} {
          display: inline-block;
        }
      }

      &.account {
        cursor: pointer;
        a {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        .chevron {
          margin-bottom: 8px;
          path {
            fill: var(--grey);
          }
        }
      }

      &.user-details {
        display: flex;
        gap: 0.5rem;
        text-align: right;
      }
    }
  }

  .size-finder-link {
    text-align: right;
    align-self: flex-end;
  }
`;
