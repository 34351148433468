import React from "react";
import Link from "next/link";
import { LogoStyles } from "./styles";
import useCartOpen from "../../lib/apollo/storage/appConfig/useCartOpen";
import useAccountMenuOpen from "../../lib/apollo/storage/appConfig/useAccountMenuOpen";
// import useCategories from "../../lib/apollo/storage/appConfig/useCategories";
// import { parseCategory } from "../../utils";

import Image from "next/image";
import useUser from "@apollo-custom/storage/appConfig/useUser";
import { useParams } from 'next/navigation';

const Logo = () => {
  const { toggleCart, cartOpen } = useCartOpen();
  const { toggleAccountMenu, accountMenuOpen } = useAccountMenuOpen();
  const { id } = useUser();
  const { locale } = useParams()
  
  return (
    <LogoStyles>
      <Link
        href={id ? `/${locale}/home` : `/${locale}/login`}
        onClick={() => {
          if (cartOpen) toggleCart();
          if (accountMenuOpen) toggleAccountMenu();
        }}
      >
        <Image priority={true} fill alt="logo" src={"/images/logo.png"} />
      </Link>
    </LogoStyles>
  );
};

export default Logo;
