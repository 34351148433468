import styled from 'styled-components';
import { device } from '../../utils/deviceSizes';

export const LogoStyles = styled.div`
  font-size: 2.4rem;
  height: fit-content;
  max-width: 20rem;
  height: 10rem;
  cursor: pointer;

  @media ${device.laptop} {
    width: 12rem;
  }

  @media ${device.tablet} {
    width: 15rem;
    padding-left: 1rem;
    

    img {
      
    }
  }
  

  @media ${device.mobileL} {
    width: 12rem;
  }


  img {
    position: static !important;
    padding: 1.5rem 0;
    /* height: 100%;
    width: 100%; */
    margin-left: -1rem;
    /* width: 100%; */
    object-fit: contain;
  }
`;
