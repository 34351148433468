import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_ACCOUNT_MENU_OPEN } from "../../queries";
import { accountMenuOpenVar } from "./apolloVars";

export default function useAccountMenuOpen() {
  const accountMenuOpen = useReactiveVar(accountMenuOpenVar);

  return {
    accountMenuOpen: accountMenuOpen?.accountMenuOpen,
    toggleAccountMenu() {
      accountMenuOpenVar({ accountMenuOpen: !accountMenuOpen.accountMenuOpen });
    },
  };
}
