"use client"

import React from "react";
import { FooterStyles } from "./styles";
import Link from "next/link";
import useStore from "../../lib/apollo/storage/appConfig/useStore";
import { getTermsAndConditionsLink } from "../../utils/getTermsAndConditionsLink";
import useUser from "../../lib/apollo/storage/appConfig/useUser";

const Footer = () => {
  const { country } = useStore();
  const { id } = useUser();

  return (
    <FooterStyles className="footer">
      <div className="footer-content">
        <p className="footer-text">
          &copy; {new Date().getFullYear()} Non-stop dogwear. All rights
          reserved.
        </p>

        {id ? (
          <a
            href={getTermsAndConditionsLink(country)}
            target="_blank"
            rel="noopener noreferrer"
          >
            View the full Terms and Conditions (PDF)
          </a>
        ) : (
          <p>
            Please{" "}
            <Link href="/login" className="orange-link">
              log in
            </Link>{" "}
            to see our Terms and Conditions
          </p>
        )}
        <Link href="/privacy-policy">Privacy Policy</Link>
      </div>
    </FooterStyles>
  );
};

export default Footer;
