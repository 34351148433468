import styled from "styled-components";
import { device } from "../../utils/deviceSizes";

export const AccountStyles = styled.div`
  padding: 4rem 7rem;
  position: absolute;
  right: 8rem;
  top: 10rem;
  z-index: 10000;

  transition: all 0.5s;
 
  background: var(--secondary);
  color: #fff;

  transform: translateY(-150%);
  opacity: 0;
  &.open {
    transform: translateY(0);
    opacity: 1;
  }

  @media ${device.tablet} {
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 8rem;
    right: 0;

    background: #fff;
    color: #000;
    transform: translateY(-150%);
    &.open {
      transform: translateY(0);
    }
    opacity: unset;
  }

  ul {
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      align-items: flex-end;
      font-size: 1.8rem;

      &:not(:last-of-type) {
        margin-bottom: 3rem;

        svg {
          path {
            fill: var(--grey2);
          }
        }
      }
      a {
        line-height: 0.5rem;
      }
      
      svg {
        width: 2rem;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
      }
      img {
        margin-right: 1rem;
      }

      svg {
        margin-bottom: 0;
      }

      span {
        height: 0.5rem;
        padding-top: 10px;
      }
    }
  }
`;
