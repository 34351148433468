import styled from "styled-components";

export const ErrorStyles = styled.div`
  background: var(--red);
  color: white;
  padding: 0 2rem;
  font-size: 1.6rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  height: fit-content;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
  /* max-width: 50%; */
`;
