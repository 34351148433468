"use client";

import React, { createContext, useContext, useState, useEffect } from "react";

import { toast } from "react-toastify";
import _ from "lodash";

import useStore from "@apollo-custom/storage/appConfig/useStore";
import useUser from "@apollo-custom/storage/appConfig/useUser";

const NewsletterContext = createContext<{
  isSubscribed: boolean | null;
  setIsSubscribed: (isSubscribed: boolean) => void;
  hasRecord: boolean;
  setHasRecord: (hasRecord: boolean) => void;
  subscribedNotify: () => void;
  unsubscribedNotify: () => void;
  subscribeMailchimp: () => void;
}>({
  isSubscribed: null,
  setIsSubscribed: (isSubscribed: boolean) => isSubscribed,
  hasRecord: false,
  setHasRecord: (hasRecord: boolean) => hasRecord,
  subscribedNotify: () => null,
  unsubscribedNotify: () => null,
  subscribeMailchimp: () => null,
});

 
export const NewsletterProvider = ({ children }) => {
  const {
    basePrice,
    city,
    country,
    currency,
    customerDefinition,
    line_1,
    name,
    priority,
    salesperson,
    warehouseName,
    zipCode,
  } = useStore();
  const { customerType, email, productLists } = useUser();

  const subscribedNotify = () =>
    toast(
      "Thank you for subscribing to our newsletter! You will receive a confirmation email from us.",
      {
        position: "top-right",
        theme: "light",
        progressStyle: { background: "var(--primary)" },
      }
    );
  const unsubscribedNotify = () =>
    toast("You have unsubscribe to our newsletter.", {
      position: "top-right",
      theme: "light",
      progressStyle: { background: "var(--primary)" },
    });

  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);
  const [hasRecord, setHasRecord] = useState(false);

  console.log(productLists, "prod lists");
  const subscribeMailchimp = async () => {
    // console.log('email', email)

    if (!hasRecord) {
      const res = await fetch("/newsletter/api", {
        method: "POST",
        body: JSON.stringify({
          action: "new subscribe",
          address: {
            city,
            country,
            line_1,
            zipCode,
          },
          basePrice,
          currency,
          customerDefinition,
          customerType,
          email,
          name,
          priority,
          salesperson,
          warehouseName,
          tags: productLists.map(({ external_ref }) => `[SEG] ${external_ref}`),
        }),
      });

      const _body = await res.json();
      // console.log('body', _body)

      if (_body?.success) {
        setIsSubscribed(true);
        setHasRecord(true);
        subscribedNotify();
      }
    } else {
      const res = await fetch("/newsletter/api", {
        method: "POST",
        body: JSON.stringify({
          email,
          action: !isSubscribed ? "subscribed" : "unsubscribed",
        }),
      });

      const _body = await res.json();
      // console.log('body', _body)

      if (_body?.success) {
        if (isSubscribed === false) {
          subscribedNotify(); // towards subscribing
          setIsSubscribed(true);
        } else if (isSubscribed === true) {
          unsubscribedNotify(); // towards unsubscribing
          setIsSubscribed(false);
        }
      }
    }
  };

  const syncVismaMailchimp = async (_body: any) => {
    if (_body?.data?.status !== "subscribed") return; // only sync subscribed users

    const vismaData = {
      address: {
        city,
        country,
        addr1: line_1,
        zip: zipCode,
      },
      basePrice,
      country,
      currency,
      customerDefinition,
      customerType,
      email,
      name,
      priority,
      salesperson,
      warehouseName,
      tags: [
        ...productLists.map(({ external_ref }) => `[SEG] ${external_ref}`),
      ].sort(),
    };

    const mailchimpData = {
      address: {
        city: _body.data.merge_fields.ADDRESS.city,
        country: _body.data.merge_fields.ADDRESS.country,
        addr1: _body.data.merge_fields.ADDRESS.addr1,
        zip: _body.data.merge_fields.ADDRESS.zip,
      },
      basePrice: _body.data.merge_fields.PRICECLAS,
      country: _body.data.merge_fields.ADDRESS.country,
      currency: _body.data.merge_fields.CURRENCY,
      customerDefinition: _body.data.merge_fields.CUSTDEFINE,
      customerType: _body.data.merge_fields.CUSTYPE,
      email: _body.data.email_address,
      name: _body.data.merge_fields.FNAME,
      priority: _body.data.merge_fields.PRIORITY,
      salesperson: _body.data.merge_fields.SALESPERSO,
      warehouseName: _body.data.merge_fields.WAREHOUSE,
      tags: _body.data.tags
        .map(({ name }) => name)
        .filter((tag: string) => tag.startsWith("[SEG]"))
        .sort(),
    };

    // console.log('vismaData', vismaData)
    // console.log('mailchimpData', mailchimpData)

    // compare visma store and mailchimp
    const result = _.pickBy(
      vismaData,
      (v, k) => !_.isEqual(mailchimpData[k], v)
    );
    // console.log('result', result)

    // keep non prefix-SEG tags already in mailchimp
    mailchimpData.tags
      .concat(
        _body.data.tags
          .map(({ name }) => name)
          .filter((tag: string) => !tag.startsWith("[SEG]"))
      )
      .sort();

    // tags about to be removed will be set to inactive as per mailchimp API
    if (result["tags"])
      result["tagsInactive"] = _.difference(mailchimpData.tags, vismaData.tags);

    if (Object.keys(result).length > 0) {
      const res = await fetch("/newsletter/api", {
        method: "PATCH",
        body: JSON.stringify({
          email,
          ...result,
        }),
      });

      await res.json();
    }
  };

  const checkSubscription = async () => {
    const res = await fetch(`/newsletter/api?email=${email}`);
    const _body = await res.json();
    // console.log('checkSubscription', _body)

    if (_body?.data?.status === "subscribed") setIsSubscribed(true);
    if (_body?.data?.status !== "subscribed") setIsSubscribed(false);
    if (_body?.success && _body?.data) {
      setHasRecord(true);
      syncVismaMailchimp(_body);
    }
  };

  useEffect(() => {
    if (email && name) checkSubscription();
  }, [email, name]);

  return (
    <NewsletterContext.Provider
      value={{
        isSubscribed,
        setIsSubscribed,
        hasRecord,
        setHasRecord,
        subscribedNotify,
        unsubscribedNotify,
        subscribeMailchimp,
      }}
    >
      {children}
    </NewsletterContext.Provider>
  );
};

export const useNewsletter = () => useContext(NewsletterContext);
