import styled from 'styled-components';
import { device } from '../../utils/deviceSizes';

export const SignOutStyles = styled.div`
  position: static;
  align-items: center;
  cursor: pointer;
  
  @media ${device.laptopL} {
    margin-right: 1rem;
  }

  @media ${device.tablet} {
    position: static;

    svg {
      width: 2.5rem !important;
      height: 2.5rem;
    }
  }
  
  svg {
    margin-right: 0.5rem;
    path {
      color: var(--primary);
    }
  }
`;
