"use client"
import React, { useEffect, useState } from "react";
// import jwtDecode from "jwt-decode";

// APOLLO
import { GET_CURRENT_USER_ORDER, ME_QUERY } from "../../lib/apollo";
import { useLazyQuery } from "@apollo/client";
import useCartItems from "../../lib/apollo/storage/appConfig/useCartItems";
import useCompany from "../../lib/apollo/storage/appConfig/useCompany";
import useNotifications from "../../lib/apollo/storage/appConfig/useNotifications";
import useUser from "../../lib/apollo/storage/appConfig/useUser";
import useActiveOrder from "../../lib/apollo/storage/appConfig/useActiveOrder";
import useStore from "../../lib/apollo/storage/appConfig/useStore";
import useHandleSignout from "../../utils/useHandleSignout";
import useIsLoggedIn from "../../lib/apollo/storage/appConfig/useIsLoggedIn";
import useCategories from "../../lib/apollo/storage/appConfig/useCategories";
import { useParams, usePathname, useRouter } from 'next/navigation';
import { useAuth } from 'src/context/AuthContext';
import { Category, Company, Order, Product, ProductList, QueryGetCurrentOrderArgs, QueryGetCurrentUserArgs, Store, User, } from 'src/generated/graphql';
import { MeQueryResponse } from '@apollo-custom/queryTypes';

const UserPage: React.FC = () => {
  const { token } = useAuth();


  const handleSignout = useHandleSignout();
  const [getCurrentUserOrder, { data: orderData, error }] = useLazyQuery<{
    getCurrentUserOrder: Order,
  }>(GET_CURRENT_USER_ORDER)

  const [getUser, { loading: userLoading, data, error: userError }] = useLazyQuery<MeQueryResponse>(ME_QUERY, {
    variables: { token: token },
    context: {
      headers: { authorization: token ? `Bearer ${token}` : "" },
    }
  });

  useEffect(() => {
    if (error?.message === "Unauthorized" || userError?.message === "Unauthorized") {
      location.reload();
    }
  }, [error, userError])

  useEffect(() => {
    if (token) {
      getUser({ variables: { token: token } });
    }
  }, [token]);

  // useEffect(() => { 
  //   if (navigator.language) {
  //     router.push(`/${navigator.language}`);
  //   }
  // }, [navigator.language]);

  const { addCartItems } = useCartItems();
  const { addCategories } = useCategories();
  const { addCompany } = useCompany();
  const { addNotifications } = useNotifications();
  const { addUser } = useUser();
  const { addIsLoggedIn, isLoggedIn } = useIsLoggedIn();
  const { addStore } = useStore();
  const { addActiveOrder } = useActiveOrder();
  const router = useRouter()

  const userData = data?.getCurrentUser;
  const notifications = userData?.notifications;
  const pathname = usePathname();

  useEffect(() => {
    if (userData && !userData.user) {
      handleSignout();
    }

    if (userData && userData.user) {
      const responsiblePerson = userData?.user?.company?.responsiblePerson;
      addIsLoggedIn(true);
      addCompany({
        id: userData?.user?.company?.id,
        currency: userData?.user?.company?.currency?.currency_code || "",
        discount: userData.user.company?.discountPercentage?.discount || 0,
        environment: userData?.user?.company?.environment?.external_ref || "",
        paymentMethods: userData.user.company?.paymentMethods,
        vatRate: userData.vatRate,
        responsiblePerson: {
          name: responsiblePerson?.name || "",
          email: responsiblePerson?.email || "",
          phoneNumber: responsiblePerson?.phoneNumber || "",
        },
      });

      addCategories({ target: "FOR_DOGS", categories: userData.categories });
      const store = userData.user.store;
      addStore({
        id: store?.id,
        external_ref: store?.external_ref,
        name: store?.name,
        line_1: store?.line_1,
        city: store?.city,
        zipCode: store?.zipCode,
        country: store?.country,
        address: `${store?.line_1}, ${store?.city}, ${store?.zipCode}, ${store?.country}`,
        warehouseId: store?.warehouseId,
        termsAndConditionsAccepted: store?.termsAndConditions?.termsAndConditionsAccepted || false,
        users: store?.users,
        basePrice: store?.basePrice?.name,
        priority: store?.priority,
        customerDefinition: store?.company?.customerDefinition,
        warehouseName: store?.warehouse?.name,
        currency: store?.company?.currency?.currency_code,
        salesperson: store?.company?.responsiblePerson?.name || store?.company?.responsiblePerson?.email || "",
        status: store?.status,
      });

      const productsParsed = userData?.productsForCampaing?.map((product) => {
        const campaigns = product?.campaigns?.map((campaign) => {
          return {
            id: campaign?.id,
            name: campaign?.name,
            label: campaign?.label,
            discount: campaign?.discount,
          };
        });
        return {
          id: product.id,
          name: product.name,
          campaign: campaigns?.[0],
        };
      });


      addUser({
        id: userData.user.id,
        email: userData.user.email,
        productLists: userData?.productLists,
        homePageUrl: userData.homePageUrl,
        productsForCampaing: productsParsed,
        userType: userData.user.userType,
        priceListLink: userData.priceListLink,
        customerType: userData.user.company?.customerType,
        isEnrolledInTeachable: userData.isEnrolledInTeachable,
        users: userData.users
      });

      const basePrice = userData?.user?.store?.basePrice?.name
      const currency = userData?.user?.company?.currency?.currency_code
      if (basePrice && currency) {
        getCurrentUserOrder({
          variables: {
            basePrice,
            currency
          },
          fetchPolicy: "network-only"
        })
      }

      if (pathname === `${locale}/login`) router.push(`/${locale}/home`);
      addNotifications(notifications);
    }
  }, [userData, getCurrentUserOrder]);

  useEffect(() => {
    const order = orderData?.getCurrentUserOrder;

    if (order) {
      addActiveOrder({
        id: order.id,
        shippingCost: order.shippingCost,
        totalPrice: order.totalPrice,
        totalVAT: order.totalVAT,
        discount: order.discountedBy,
        vatRate: order.vatRate,
      });
      addCartItems(order?.items);
    }

  }, [orderData])

  const { locale } = useParams();

  useEffect(() => {

    if (isLoggedIn && pathname === `/${locale}`) {
      router.push(`/${locale}/home`);
    }
    if (isLoggedIn && (pathname === "/" || pathname === `/${locale}/login`)) {
      router.push(`/${locale}/home`);
    } else if (!isLoggedIn && pathname !== `/${locale}/login` && pathname !== `/${locale}/reset` && pathname !== `/${locale}/sign-up`) {
      router.push(`/${locale}/login`);
    }

    //  if ((pathname !== `${locale}/login` && pathname !== `${locale}/reset` && pathname !== `${locale}/sign-up`) && !isLoggedIn) {
    //   router.push(`/${locale}/login`);
    // }
  }, [isLoggedIn, locale]);

  return null;
};

export default UserPage;