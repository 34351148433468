
"use client";
import cache from '@apollo-custom/storage/cache';
import { HttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  ApolloClient,
} from "@apollo/experimental-nextjs-app-support";
import { getApiEndPoint } from "src/config";

// have a function to create a client for you
function makeClient(token) {
  const httpLink = new HttpLink({
    // this needs to be an absolute url, as relative urls cannot be used in SSR
    uri: getApiEndPoint(),
    headers: {
      authorization: `Bearer ${typeof window !== "undefined" ? localStorage.getItem("token") : token}`,
    },
    fetchOptions: { cache: "no-store" },
    // you can override the default `fetchOptions` on a per query basis
    // via the `context` property on the options passed as a second argument
    // to an Apollo Client data fetching hook, e.g.:
  });

  // use the `ApolloClient` from "@apollo/experimental-nextjs-app-support"
  return new ApolloClient({

    
    // use the `InMemoryCache` from "@apollo/experimental-nextjs-app-support"
    cache,
    link: httpLink,
  });
}

// you need to create a component to wrap your app in
export function ApolloWrapper({ children, cookieToken }: { children: React.ReactNode, cookieToken: string }) {

  const client = makeClient(cookieToken); // Create Apollo client instance
  return (
    <ApolloNextAppProvider makeClient={() => client}>
      {children}
    </ApolloNextAppProvider>
  );
}