"use client";
import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useCompany from "@apollo-custom/storage/appConfig/useCompany";
import * as Sentry from "@sentry/nextjs";

const StripeProvider = ({ environment, children }) => {

  // Determine which key to use based on the environment
  const getStripeKey = () => {
    if (!environment) {
      return null; // Don't load Stripe if environment is not set
    }

    // Dynamically select the key based on environment
    switch (environment) {
      case "NO":
        return process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_NO || "";
      case "SE":
        return process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_SE || "";
      default:
        return process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || "";
    }
  };

  const stripeKey = getStripeKey();

  if (!environment) { 
    console.warn("Environment not set for StripeProvider.");
    return <>{children}</>; // Render children
  }

  if (!stripeKey) {
    console.warn("Stripe key not set for the current environment.");
    Sentry.captureMessage(`
      Stripe key not set for the current environment.
      Environment: ${environment}
    `);
    return <>{children}</>; // Render children without Stripe
  }

  const stripePromise = loadStripe(stripeKey);

  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default StripeProvider;
