"use client";
import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";

// APOLLO
import useCategories from "../../lib/apollo/storage/appConfig/useCategories";
import useIsLoggedIn from "../../lib/apollo/storage/appConfig/useIsLoggedIn";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { SEARCH_PRODUCTS } from "../../lib/apollo";

// COMPONENTS
import Logo from "../Logo";
import CategoryLink from "../Categories/CategoryLink";
import Account from "../Account";
import Nav from "./Nav";

// STYLES
import { HeaderStyles } from "./styles";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// UTILS
import useUser from "../../lib/apollo/storage/appConfig/useUser";
import slugify from "slugify";
import useWindowSize from "../../utils/useWindowSize";
import { Product, QuerySearchProductsArgs } from 'src/generated/graphql';

// Dynamically import ProductSearch to load only on the client side
const ProductSearch = dynamic(() => import("./ProductSearch"), { ssr: false });

const Header: React.FC = ({ }) => {
  const { target } = useCategories();
  const user = useUser();
  const { isLoggedIn } = useIsLoggedIn();
  const apolloClient = useApolloClient();
  const { width } = useWindowSize();
  const router = useRouter();

  const { homePageUrl, email } = user;

  const [linkTargetsOpen, setLinkTargetsOpen] = useState(false);

  const [searchData] = useLazyQuery<{
    searchProducts: Product[];
  }, QuerySearchProductsArgs>(SEARCH_PRODUCTS, {
    variables: { searchQuery: "" },
  });

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <HeaderStyles className={`header ${linkTargetsOpen ? "open" : ""}`}>
      <div className="container">
        <Account />
        <div className="container__left">
          <Logo />
          <div
            className={`targets-container ${!isLoggedIn || !email ? "hidden" : ""
              }`}
          >
            <p
              className="link-targets__opener"
              onClick={() => setLinkTargetsOpen(!linkTargetsOpen)}
            >
              {target?.split("_").join(" ")}{" "}
              <FontAwesomeIcon width={15} height={15} icon={faSortDown} />
            </p>
            <div
              onClick={() => setLinkTargetsOpen(false)}
              className="link-targets link-targets-mobile"
            >
              {linkTargetsOpen && (
                <>
                  <CategoryLink
                    id={"shop"}
                    name={"SHOP"}
                    englishName='harnesses'
                    slug={homePageUrl}
                    productSlug={homePageUrl}
                    currentCategory={"harnesses"}
                    products={() => { }}
                  />
                </>
              )}
            </div>

            {homePageUrl && <div className="link-targets-desktop">
              <CategoryLink
                id={""}
                name={"SHOP"}
                value={"harnesses"}
                englishName={"harnesses"}
                slug=""
                productSlug={homePageUrl?.split("/")?.pop() || ""}
                currentCategory={"harnesses"}
                products={() => { }}
              />
            </div>}
          </div>
        </div>

        <div className='product-search-container' onClick={() => searchData()}>
          {/* Render ProductSearch as a client-only component */}
          {isClient && (
            <ProductSearch isLoggedIn={isLoggedIn} email={email} />
          )}{" "}
        </div>

        <Nav className="nav__desktop" />
      </div>
    </HeaderStyles>
  );
};

export default Header;
