import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import useAccountMenuOpen from '../../lib/apollo/storage/appConfig/useAccountMenuOpen';

import useCartOpen from '../../lib/apollo/storage/appConfig/useCartOpen';
import { getTotalCartItems } from '../../utils/cartUtils';
import { CartWidgetStyles, Dot } from './styles';

// import { ShoppingCartOutlined } from '@ant-design/icons';

// interface CartWidgetProps {
//   // totalItems: number;
// }

const CartWidget: React.FC = () => {
  const { toggleCart, cartOpen } = useCartOpen();
  const { toggleAccountMenu, accountMenuOpen } = useAccountMenuOpen();
  const totalItems = getTotalCartItems();

  return (
    // <h1>cart</h1>
    <CartWidgetStyles className={cartOpen ? "open" : ""} onClick={() => {
      toggleCart()
      if (accountMenuOpen) toggleAccountMenu()
    }}>
      <FontAwesomeIcon icon={faShoppingCart} />
      {totalItems > 0 && (
        <Dot>{totalItems}</Dot>
      )}
    </CartWidgetStyles>
  );
};

export default CartWidget;
