export default [
  {
    href: "/home",
    text: "Homepage",
    icon: "faHouseUser",
  },
  {
    href: "/category/booties",
    text: "Shop",
    image: "new-order.png",
  },
  {
    href: "/my-orders",
    text: "Orders",
    image: "all-orders.png",
  },
  // {
  //   href: "/account",
  //   text: "Messages",
  //   image: "envelope.png"
  // },
  {
    target: "_blank",
    href: "https://www.dropbox.com/sh/wejglfrdcr3hhpx/AAAHQBZRRnZhRK-IzIdaJQ6la?dl=0",
    text: "Images",
    image: "product-images.png",
  },

  {
    target: "_blank",
    href: "https://non-stop.typeform.com/to/Md0pA3el",
    text: "Reclamation Form",
    icon: "faShieldAlt",
  },
  {
    target: "_blank",
    href: "https://sites.saleslayer.com/Q0NCeXFBWFhBVzQ9",
    text: "Product Text",
    icon: "faPen",
  },

  {
    // target: "_blank",
    href: "/account",
    text: "Your Account",
    icon: "faCogs",
  },
  // {
  //   // target: "_blank",
  //   href: "/size-finder",
  //   text: "Size finder",
  //   icon: "faDog",
  // },

  // {
  //   href: "/category/booties",
  //   text: "Download",
  //   image: "download.png"
  // },
];
