"use client"
import React, { useRef, useEffect, useCallback } from 'react';

import useModal from '../../lib/apollo/storage/appConfig/useModal';
import ErrorHandler from '../ErrorHandler';
import {
  Background,
  CloseModalButton,
  ModalContent,
  ModalWrapper,
} from './styles';
import styled from 'styled-components';


export const ConfirmModalStyles = styled.div`
  padding: 2rem;

  .title {
    font-size: 2.4rem;
  }

  .subtitle {
    font-size: 2rem;
    font-weight: bold;
  }

  .block-wrapper {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    & > * {
      padding: 1rem;
      color: #fff;
    }
    .green {
      background: var(--green);
    }
    .red {
      background: var(--red);
    }
  }
`;

export const SuccessModalStyles = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    padding: 3rem !important;
    font-size: 2rem;
  }
`;


const ModalComponent = () => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const {
    isOpen,
    element: Element,
    props,
    message,
    error,
    toggleModal,
  } = useModal();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      toggleModal({ isOpen: false });
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && isOpen) {
        toggleModal({ isOpen: false });
      }
    },
    [toggleModal, isOpen]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <>
      {isOpen ? (
        <Background onClick={closeModal} ref={modalRef}>
          <div>
            <ModalWrapper>
              {/* <ModalImg src={require('./modal.jpg')} alt='camera' /> */}
              <ModalContent>
                {Element && <Element props={props} />}
                {message && <p>{message}</p>}
                {error && <ErrorHandler message={error} />}
                {/* <button>Join Now</button> */}
              </ModalContent>
              <CloseModalButton
                aria-label="Close modal"
                onClick={() => toggleModal({ isOpen: false })}
              >
                X
              </CloseModalButton>
            </ModalWrapper>
          </div>
        </Background>
      ) : null}
    </>
  );
};
export default ModalComponent;
