"use client"
import useCartOpen from '@apollo-custom/storage/appConfig/useCartOpen';
import React from 'react';
import styled from 'styled-components';

const OverlayStyled = styled.div`
  position: absolute;
  z-index: 9999 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  &.show {
    display: block;
  }
`;

const overlay = () => {

  const { cartOpen, toggleCart } = useCartOpen();
  return (
    <OverlayStyled className={cartOpen ? "show" : ""} onClick={() => toggleCart()} />
  );
};

export default overlay;