import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_MODAL } from "../../queries";
import { modalVar } from "./apolloVars";

export default function useModal() {
  const modal = useReactiveVar(modalVar);

  return {
    isOpen: modal?.isOpen,
    element: modal?.element,
    message: modal?.message,
    error: modal?.error,
    props: modal?.props,
    addModalData({ element, message, error, props }) {
      modalVar({
        isOpen: true,
        props: props ? props : modal?.props,
        element: element ? element : modal?.element,
        message: message ? message : modal?.message,
        error: error ? error : modal?.error,
      });
    },

    toggleModal({ isOpen }) {
      modalVar({
        isOpen,
        props: modal?.props,
        element: modal?.element,
        message: modal?.message,
        error: modal?.error,
      });
    },
  };
}
