import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React from "react";
import useAccountMenuOpen from "../../lib/apollo/storage/appConfig/useAccountMenuOpen";
import {
  faCogs,
  faHouseUser,
  faPen,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import useCategories from "../../lib/apollo/storage/appConfig/useCategories";
import { parseCategory } from "../../utils";
import useModal from "../../lib/apollo/storage/appConfig/useModal";
import NoSegmentationWarning from "../User/NoSegmentationWarning";
import Image from "next/image";
import useWindowSize from "../../utils/useWindowSize";
import useUser from "../../lib/apollo/storage/appConfig/useUser";

const AccountLink = ({ href, text, image, target, icon }) => {
  const { toggleAccountMenu } = useAccountMenuOpen();
  const { width } = useWindowSize();
  const { homePageUrl } = useUser();

  const { categories } = useCategories();
  const firstCategory =
    categories?.[0]?.name && parseCategory(categories[0].name).toLowerCase();

  const { toggleModal, addModalData } = useModal();

  return (
    <li>
      {text !== "New Order" ? (
        href && (
          <Link href={href} onClick={() => toggleAccountMenu()} target={target}>
            {image && (
              <Image
                alt={text}
                width={20}
                height={20}
                src={`/images/${image}`}
              />
            )}
            {icon === "faShieldAlt" && <FontAwesomeIcon icon={faShieldAlt} />}
            {icon === "faPen" && <FontAwesomeIcon icon={faPen} />}
            {icon === "faHouseUser" && <FontAwesomeIcon icon={faHouseUser} />}
            {icon === "faCogs" && <FontAwesomeIcon icon={faCogs} />}
            <span>{text}</span>
          </Link>
        )
      ) : (
        <Link
          href={homePageUrl || ""}
          className="clickable"
          onClick={() => {
            if (homePageUrl === "NO_PRODUCTS") {
              toggleModal({ isOpen: true });
              addModalData({
                message: "",
                error: "",
                element: () => <NoSegmentationWarning />,
                props: {},
              });
            }
          }}
          target={target}
        >
          {image && (
            <Image alt={text} width={18} height={24} src={`/images/${image}`} />
          )}{" "}
          <span>{text}</span>
        </Link>
      )}
    </li>
  );
};

export default AccountLink;
